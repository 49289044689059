import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { addBusiness, resetMessage } from  '../../store/actions/businessActions'


class NewBusinessModal extends React.Component{
    state = {
		biz_title               : '',
		biz_email               : '',
		biz_phone               : '',
		biz_website             : '',
		biz_website_appointment : '',
		biz_website_responsive  : '',
		biz_website_contact_form: '',
		biz_website_modern      : '',

		biz_category: '',
		biz_source  : '',

        ds_title  : '',
        ds_details: '',
        error     : {},
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.addBusiness( {
			biz_title               : this.state.biz_title,
			biz_email               : this.state.biz_email,
			biz_phone               : this.state.biz_phone,
			biz_website             : this.state.biz_website,
			biz_website_appointment : this.state.biz_website_appointment,
			biz_website_responsive  : this.state.biz_website_responsive,
			biz_website_contact_form: this.state.biz_website_contact_form,
			biz_website_modern      : this.state.biz_website_modern,
			biz_category            : this.state.biz_category,
			biz_source              : this.state.biz_source,
		} )
	}

	componentDidMount(){
		this.props.resetMessage()
	}

    changeHandler = event => {
		console.log('type', event.target.name )
		console.log('value', event.target.value )

        this.setState({
            [event.target.name]: event.target.value
        })
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let { biz_title, biz_website,
			biz_website_contact_form,
			biz_email,
			biz_phone,
			biz_website_appointment,
			biz_website_responsive,
			biz_website_modern,
			biz_category,
			biz_source } = this.state

		let { error }                = this.props.business

		let { categories, sources } = this.props
		let allCategories, allSources

		if(categories && categories.data){
			allCategories      = categories.data.length ? categories.data : null
		}
		if(sources && sources.data){
			allSources      = sources.data.length ? sources.data : null
		}

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h1>Add new business</h1>

					<form onSubmit={this.submitHandler} className="">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_category">Category:</label>
									<select
										className = {
											( error.biz_category ? "form-control is-invalid" : "form-control" )
										}
										name      = "biz_category"
										onChange  = { this.changeHandler }
										>

										<option value="">Select Category</option>

										{ allCategories ? allCategories.map( itemInfo => (
											<option value={ itemInfo.CategoryID } key={ itemInfo.CategoryID }>{ itemInfo.category }</option>
										) ) : '' }

									</select>
									{ error.biz_category &&(
										<div
											className="invalid-feedback">
											{ error.biz_category }
										</div>
									) }
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_source">Source:</label>
									<select
										className = {
											( error.biz_source ? "form-control is-invalid" : "form-control" )
										}
										name      = "biz_source"
										onChange  = { this.changeHandler }
										>

										<option value="">Select Source</option>

										{ allSources ? allSources.map( itemInfo => (
											<option value={ itemInfo.DSID } key={ itemInfo.DSID }>{ itemInfo.ds_title }</option>
										) ) : '' }

									</select>
									{ error.biz_source &&(
										<div
											className="invalid-feedback">
											{ error.biz_source }
										</div>
									) }
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_title">Title:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_title ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_title"
										id          = "biz_title"
										onChange    = { this.changeHandler }
										value       = { biz_title }
									/>
									{ error.biz_title &&(
										<div
											className="invalid-feedback">
											{ error.biz_title }
										</div>
									) }
								</div>
							</div>

							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_website">Website:</label>
									<input
										type        = "url"
										className   = {
											( error.biz_website ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_website"
										id          = "biz_website"
										onChange    = { this.changeHandler }
										value       = { biz_website }
									/>
									{ error.biz_website &&(
										<div
											className="invalid-feedback">
											{ error.biz_website }
										</div>
									) }
								</div>
							</div>

							{ biz_website?
								<div className="col-md-12">
									<div className="form-group has-error">
										<label className="control-label" for="biz_website_contact_form">Contact Form:</label>
										<input
											type        = "url"
											className   = {
												( error.biz_website_contact_form ? "form-control is-invalid" : "form-control" )
											}
											placeholder = ""
											name        = "biz_website_contact_form"
											id          = "biz_website_contact_form"
											onChange    = { this.changeHandler }
											value       = { biz_website_contact_form }
										/>
										{ error.biz_website_contact_form &&(
											<div
												className="invalid-feedback">
												{ error.biz_website_contact_form }
											</div>
										) }
									</div>
								</div>
							:null }

						</div>

						<div className="row">
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_email">Email:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_email ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_email"
										id          = "biz_email"
										onChange    = { this.changeHandler }
										value       = { biz_email }
									/>
									{ error.biz_email &&(
										<div
											className="invalid-feedback">
											{ error.biz_email }
										</div>
									) }
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_phone">Phone:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_phone ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_phone"
										id          = "biz_phone"
										onChange    = { this.changeHandler }
										value       = { biz_phone }
									/>
									{ error.biz_phone &&(
										<div
											className="invalid-feedback">
											{ error.biz_phone }
										</div>
									) }
								</div>
							</div>
						</div>

						<div className="form-group has-error">
							<button
								className="btn btn-primary mr-4 mb-2 is-invalid">Submit</button>

								{ this.props.business.success?
									<font className="text-success">
										{ this.props.business.success }
									</font>
									:null
								}

								{ error.error &&(
									<font className="invalid-feedback">
										{ error.error }
									</font>
								) }
						</div>
					</form>

				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    business  : state.business,
    sources   : state.source,
    categories: state.category,
})
const mapDispatchToProps = ({
	addBusiness,
	resetMessage
})
export default connect( mapStateToProps, mapDispatchToProps )(NewBusinessModal)
