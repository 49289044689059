import * as Types from '../actions/types'

const init = {
    data      : {},
    pagination: {},
    error     : {}
}

const addressReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.LOAD_ADDRESS: {
			return {
				data      : action.payload.address.data,
				pagination: action.payload.address.pagination,
				error     : {}
			}
		}

		case Types.UPDATE_ADDRESS: {

			let data = state.data

			if(action.payload.address){
				let newCategories = data.map(ctg => {
					if (ctg.ZipCode == action.payload.address.data.ZipCode) {
						return action.payload.address.data
					}
					return ctg
				})
				state.data = newCategories
			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		default: return state
	}
}

export default addressReducer