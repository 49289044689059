import {combineReducers} from 'redux'
import authReducer from './authReducer'
import categoryReducer from './categoryReducer'
import businessReducer from './businessReducer'
import businessTaskReducer from './businessTaskReducer'
import addressReducer from './addressReducer'
import stateReducer from './stateReducer'
import sourceReducer from './sourceReducer'

const rootReducer = combineReducers({
    auth        : authReducer,
    category    : categoryReducer,
    business    : businessReducer,
    businessTask: businessTaskReducer,
    address     : addressReducer,
    states      : stateReducer,
    source      : sourceReducer,
})

export default rootReducer