import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { updateBusiness, resetMessage } from  '../../store/actions/businessActions'


class EditBusiness extends React.Component{
    state = {
        BusinessID              : '',
        biz_title               : '',
        biz_website             : '',
        biz_email               : '',
        biz_phone               : '',
        biz_listing_url         : '',
        biz_address         : '',
        biz_details             : '',
        biz_website_appointment : '',
        biz_website_responsive  : '',
        biz_website_contact_form: '',
        biz_website_modern      : '',
        error                   : {},
        message                 : '',
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.updateBusiness( {
			BusinessID              : this.props.BusinessID,
			biz_title               : this.state.biz_title,
			biz_website             : this.state.biz_website,
			biz_email               : this.state.biz_email,
			biz_phone               : this.state.biz_phone,
			biz_details             : this.state.biz_details,
			biz_address             : this.state.biz_address,
			biz_website_contact_form: this.state.biz_website_contact_form,
			biz_website_appointment : this.state.biz_website_appointment,
			biz_website_responsive  : this.state.biz_website_responsive,
			biz_website_modern      : this.state.biz_website_modern,
		} )
	}

	componentDidMount(){
        this.props.resetMessage()

        this.setState({
            biz_title               : this.props.businessInfo.biz_title,
            biz_website             : this.props.businessInfo.biz_website,
            biz_email               : this.props.businessInfo.biz_email,
            biz_phone               : this.props.businessInfo.biz_phone,
            biz_details             : this.props.businessInfo.biz_details,
            biz_listing_url         : this.props.businessInfo.biz_listing_url,
            biz_address             : this.props.businessInfo.biz_address,
            biz_website_contact_form: this.props.businessInfo.biz_website_contact_form,
            biz_website_appointment : this.props.businessInfo.biz_website_appointment,
            biz_website_responsive  : this.props.businessInfo.biz_website_responsive,
            biz_website_modern      : this.props.businessInfo.biz_website_modern,
        })
	}

    changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let {
			biz_title,
			biz_website,
			biz_email,
			biz_details,
			biz_address,
			biz_listing_url,
			biz_website_contact_form,
			biz_website_appointment,
			biz_website_responsive,
			biz_website_modern,
			biz_phone } = this.state

		let error                    = {}
		    biz_website_appointment  = biz_website_appointment?true:false
		    biz_website_responsive   = biz_website_responsive?true:false
		    biz_website_modern       = biz_website_modern?true:false

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h3>
						Edit {biz_title}
					</h3>
					<div>
						{ biz_website?
							<a href={biz_website} target="_blank">
								<i class="fas fa-external-link-alt"></i>
							</a>
						:null
						}

						{ biz_listing_url?
							<a href={biz_listing_url} target="_blank">
								Source <i class="fas fa-external-link-alt"></i>
							</a>
						:null
						}
					</div>
					<form onSubmit={this.submitHandler} className="">
						<input type="hidden" name="CategoryID" value={ this.props.CategoryID }/>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_title">Title:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_title ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_title"
										id          = "biz_title"
										onChange    = { this.changeHandler }
										value       = { biz_title }
									/>
									{ error.biz_title &&(
										<div
											className="invalid-feedback">
											{ error.biz_title }
										</div>
									) }
								</div>
							</div>

							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_website">Website:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_website ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_website"
										id          = "biz_website"
										onChange    = { this.changeHandler }
										value       = { biz_website }
									/>
									{ error.biz_website &&(
										<div
											className="invalid-feedback">
											{ error.biz_website }
										</div>
									) }
								</div>
							</div>

							{ biz_website?
								<div className="col-md-12">
									<div className="form-group has-error">
										<label className="control-label" for="biz_website_contact_form">Contact Form:</label>
										<input
											type        = "url"
											className   = {
												( error.biz_website_contact_form ? "form-control is-invalid" : "form-control" )
											}
											placeholder = ""
											name        = "biz_website_contact_form"
											id          = "biz_website_contact_form"
											onChange    = { this.changeHandler }
											value       = { biz_website_contact_form }
										/>
										{ error.biz_website_contact_form &&(
											<div
												className="invalid-feedback">
												{ error.biz_website_contact_form }
											</div>
										) }
									</div>
								</div>
							:null }

							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_address">Business Address:</label>
									<textarea
										className   = {
											( error.biz_address ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_address"
										id          = "biz_address"
										onChange    = { this.changeHandler }>{ biz_address }</textarea>
									{ error.biz_address &&(
										<div
											className="invalid-feedback">
											{ error.biz_address }
										</div>
									) }
								</div>
							</div>

							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_details">Business Details:</label>
									<textarea
										className   = {
											( error.biz_details ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_details"
										id          = "biz_details"
										onChange    = { this.changeHandler }>{ biz_details }</textarea>
									{ error.biz_details &&(
										<div
											className="invalid-feedback">
											{ error.biz_details }
										</div>
									) }
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_email">Email:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_email ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_email"
										id          = "biz_email"
										onChange    = { this.changeHandler }
										value       = { biz_email }
									/>
									{ error.biz_email &&(
										<div
											className="invalid-feedback">
											{ error.biz_email }
										</div>
									) }
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="biz_phone">Phone:</label>
									<input
										type        = "text"
										className   = {
											( error.biz_phone ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "biz_phone"
										id          = "biz_phone"
										onChange    = { this.changeHandler }
										value       = { biz_phone }
									/>
									{ error.biz_phone &&(
										<div
											className="invalid-feedback">
											{ error.biz_phone }
										</div>
									) }
								</div>
							</div>
						</div>

						{ biz_website?
							<div className="row">
								<div className="col-md-3">
									<div className="form-group has-error">
										<div className="checkbox">
											<label>
												<input
													name     = "biz_website_appointment"
													checked  = { biz_website_appointment }
													onChange = { this.changeHandler }
													value    = { biz_website_appointment }
													type     = "checkbox"
													className = "mr-2"/>
													Have appointment
											</label>
										</div>

										{ error.biz_email &&(
											<div
												className="invalid-feedback">
												{ error.biz_email }
											</div>
										) }
									</div>
								</div>

								<div className="col-md-3">
									<div className="form-group has-error">
										<div className="checkbox">
											<label>
												<input
													name     = "biz_website_responsive"
													checked  = { biz_website_responsive }
													onChange = { this.changeHandler }
													value    = { biz_website_responsive }
													type     = "checkbox"
													className = "mr-2"/>
												Responsive
											</label>
										</div>

										{ error.biz_email &&(
											<div
												className="invalid-feedback">
												{ error.biz_email }
											</div>
										) }
									</div>
								</div>

								<div className="col-md-3">
									<div className="form-group has-error">
										<div className="checkbox">
											<label>
												<input
													name     = "biz_website_modern"
													checked  = { biz_website_modern }
													onChange = { this.changeHandler }
													value    = { biz_website_modern }
													type     = "checkbox"
													className = "mr-2"/>
												Modern
											</label>
										</div>

										{ error.biz_email &&(
											<div
												className="invalid-feedback">
												{ error.biz_email }
											</div>
										) }
									</div>
								</div>

							</div>
						:null }

						<div className="form-group has-error">
							<button
								className="btn btn-primary mr-4 mb-2 is-invalid">Update</button>

								{ this.props.business.success?
									<font className="text-success">
										{ this.props.business.success }
									</font>
									:null
								}

								{ error.error &&(
									<font className="invalid-feedback">
										{ error.error }
									</font>
								) }
						</div>
					</form>
				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    business: state.business
})
export default connect( mapStateToProps, { updateBusiness, resetMessage } )( EditBusiness )
