import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'

class PrimaryMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user    : '',
            password: '',
            error   : {},
		}
    }

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = event => {
		event.preventDefault()

        this.props.logout( this.props.history )
    }

    render(){
        // let { user, password, error } = this.state
        return(

			<div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
				<Link to="/" className="h5 my-0 mr-md-auto font-weight-normal"> Prism Leads </Link>

				{
					this.props.auth.isAuthenticated ?
					<nav className="my-2 my-md-0 mr-md-3">
						<Link to="/address" className="p-2 text-dark"> Address </Link>
						<Link to="/business" className="p-2 text-dark"> Business </Link>
						<Link to="/category" className="p-2 text-dark"> Category </Link>
						<Link to="/tasks" className="p-2 text-dark"> Tasks </Link>
						<Link to="/source" className="p-2 text-dark"> Source </Link>
					</nav>
					:null
				}

				{
					this.props.auth.isAuthenticated ?
					<a className="btn btn-outline-primary"
						onClick={this.submitHandler}
						href="/">Logout</a>
					:
					<Link to="/login" className="btn btn-outline-primary"> Login </Link>
				}
			</div>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})
const mapDispatchToProps = ({
	logout
})
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( PrimaryMenu ) )