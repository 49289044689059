import * as Types from '../actions/types'

const init = {
    data      : {},
    pagination: {},
    error     : {}
}

const categoryReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.LOAD_CATEGORY: {
			return {
				data      : action.payload.category.data,
				pagination: action.payload.category.pagination,
				error     : {}
			}
		}
		case Types.ADD_CATEGORY: {

			if( state.data ){
				if(action.payload.category){
					state.data.unshift( action.payload.category.data )
				}
			}else{
				state.data = action.payload.category.data
			}

			return {
				...state,
				error: action.payload.error,
			}
		}
		case Types.UPDATE_CATEGORY: {

			let data = state.data

			if(action.payload.category){
				let newCategories = data.map(ctg => {
					if (ctg.CategoryID == action.payload.category.data.CategoryID) {
						return action.payload.category.data
					}
					return ctg
				})
				state.data = newCategories
			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		case Types.DELETE_CATEGORY: {
			if(action.payload.category){

				let newStateDate = state.data.filter( category => {
					return category.CategoryID != action.payload.category
				})
				state.data = newStateDate

			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		default: return state
	}
}

export default categoryReducer