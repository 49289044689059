import * as Types from '../actions/types'

const init = {
    data      : {},
    pagination: {},
    error     : {},
    success   : '',
}

const stateReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.LOAD_STATES: {
			return {
				data      : action.payload.states.data,
				pagination: action.payload.states.pagination,
				error     : {}
			}
		}

		default: return state
	}
}

export default stateReducer