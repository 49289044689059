import * as Types from '../actions/types'

const init = {
    data      : {},
    pagination: {},
    error     : {}
}

const sourceReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.LOAD_SOURCE: {
			return {
				data      : action.payload.category.data,
				pagination: action.payload.category.pagination,
				error     : {}
			}
		}
		case Types.ADD_SOURCE: {

			if( state.data ){
				if(action.payload.category){
					state.data.unshift( action.payload.category.data )
				}
			}else{
				state.data = action.payload.category.data
			}

			return {
				...state,
				error: action.payload.error,
			}
		}
		case Types.UPDATE_SOURCE: {

			let data = state.data
			let payloadData = action.payload.source

			if(payloadData){
				let newCategories = data.map(ctg => {
					if (ctg.DSID == payloadData.data.DSID) {
						return payloadData.data
					}
					return ctg
				})
				state.data = newCategories
			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		case Types.DELETE_SOURCE: {
			let payloadData = action.payload.source
			console.log('payloadData', payloadData)

			if(payloadData){
				let newStateDate = state.data.filter( itemInfo => {
					return itemInfo.DSID != payloadData
				})
				state.data = newStateDate

			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		default: return state
	}
}

export default sourceReducer