import * as Types from '../actions/types'

const init = {
    data      : {},
    pagination: {},
    error     : {},
    success   : '',
}

const businessReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.LOAD_BUSINESS: {
			return {
				data      : action.payload.business.data,
				pagination: action.payload.business.pagination,
				error     : {}
			}
		}
		case Types.NEW_BUSINESS: {
			if( state.data ){
				if(action.payload.business){
					state.data.unshift( action.payload.business.data )
				}
			}else{
				state.data = action.payload.business.data
			}

			return {
				...state,
				error: action.payload.error,
				success: 'Successfully Inserted',
			}
		}

		case Types.UPDATE_BUSINESS: {

			let data = state.data

			let newBusiness = data.map(business => {
                if (business.BusinessID == action.payload.business.data.BusinessID) {
                    return action.payload.business.data
                }
                return business
			})

			state.data = newBusiness
			return {
				...state,
				error  : action.payload.error,
				success: 'Successfully updated',
			}
		}

		case Types.DELETE_BUSINESS: {
			if(action.payload.business){

				let newBusiness = state.data.filter( business => {
					return business.BusinessID != action.payload.business
				})
				state.data = newBusiness

			}

			return {
				...state,
				error: action.payload.error,
			}
		}


		case Types.BUSINESS_TASK_COMPLETE: {
			if(action.payload.business){

				let newBusiness = state.data.filter( business => {
					return business.BusinessID != action.payload.business
				})
				state.data = newBusiness
			}

			return {
				...state,
				error: action.payload.error,
			}
		}

		case Types.UPDATE_RESET: {
			return {
				...state,
				success: null,
			}
		}

		default: return state
	}
}

export default businessReducer