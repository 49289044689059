import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { updateSource, resetMessage } from  '../../store/actions/sourceActions'


class EditSourceModal extends React.Component{
    state = {
        DSID      : '',
        ds_title  : '',
        ds_details: '',
        error     : {},
        message   : '',
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.updateSource( {
			DSID      : this.props.DSID,
			ds_title  : this.state.ds_title,
			ds_details: this.state.ds_details
		} )
	}

	componentDidMount(){
        this.props.resetMessage()

		this.setState({
            ds_title  : this.props.sourceInfo.ds_title,
            ds_details: this.props.sourceInfo.ds_details
        })
	}

    changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let {
			ds_title,
			ds_details } = this.state

		let error                    = {}


		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h3> Edit {ds_title} </h3>
					<form onSubmit={this.submitHandler} className="">
						<input type="hidden" name="CategoryID" value={ this.props.CategoryID }/>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="biz_website">Title:</label>
									<input
										type        = "text"
										className   = {
											( error.ds_title ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "ds_title"
										id          = "ds_title"
										onChange    = { this.changeHandler }
										value       = { ds_title }
									/>
									{ error.ds_title &&(
										<div
											className="invalid-feedback">
											{ error.ds_title }
										</div>
									) }
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="ds_details">Details:</label>
									<textarea
										className   = {
											( error.ds_details ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "ds_details"
										id          = "ds_details"
										onChange    = { this.changeHandler }
										value       = { ds_details }
									/>
									{ error.ds_details &&(
										<div
											className="invalid-feedback">
											{ error.ds_details }
										</div>
									) }
								</div>
							</div>

						</div>

						<div className="form-group has-error">
							<button
								className="btn btn-primary mr-4 mb-2 is-invalid">Update</button>

								{ this.props.business.success?
									<font className="text-success">
										{ this.props.business.success }
									</font>
									:null
								}

								{ error.error &&(
									<font className="invalid-feedback">
										{ error.error }
									</font>
								) }
						</div>
					</form>
				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    business: state.business
})

const mapDispatchToProps = ({
	updateSource,
	resetMessage
})
export default connect( mapStateToProps, mapDispatchToProps )( EditSourceModal )
