import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate';


import { logout } from '../store/actions/authActions'
import { loadBusiness, deleteBusiness, loadBusinessTask, completeBusinessTask } from '../store/actions/businessActions'

import EditBusiness from '../components/business/EditBusiness'
import NewBusinessModal from '../components/business/NewBusiness'


class Business extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			FilterAreaStatus    : false,
			addBusinessModalOpen: false,

            editBusinessModalOpen: false,
            editBusiness         : false,
            editBusinessID       : false,

			category        : false,
			businessTaskID  : false,
			order_by        : false,
			keyword         : false,
			page            : 1,
			has_website     : false,
			has_contact_form: false,
			is_modern       : false,
			is_responsive   : false,
			has_email       : false,
			has_phone       : false,

			has_contact_form: false,
			has_not_phone   : false,
			has_not_email   : false,

            error                : {},
        }
	}

	collapseFilterArea = () => {
		this.setState({
			FilterAreaStatus : this.state.FilterAreaStatus? false : true
		})
	}

	openAddBusinessModal = () => {
		this.setState({
			addBusinessModalOpen : true
		})
	}
	closeAddBusinessModal = () => {
		this.setState({
			addBusinessModalOpen : false
		})
	}

	openEditBusinessModal = business => {
		this.setState({
			...this.state,
			editBusiness         : business,
			editBusinessID       : business.BusinessID,
			editBusinessModalOpen: true,
		})
	}
	closeEditBusinessModal = () => {
		this.setState({
			...this.state,
			editBusiness         : false,
			editBusinessID       : false,
			editBusinessModalOpen: false
		})
	}

    deleteHandler = BusinessID => {
        this.props.deleteBusiness( {BusinessID} )
	}

    taskCompleteHandler = Info => {
        this.props.completeBusinessTask( Info )
	}

	changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	changeAndLoad = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			}, () => {
				this.loadBusiness()
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			}, () => {
				this.loadBusiness()
			})
		}
    }

    submitHandler = event => {
        event.preventDefault()
	}

    handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.loadBusiness()
		});
    }
    loadBusiness() {
		let { page, keyword,
			category,
			businessTaskID,
			order_by,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		} = this.state

		this.props.loadBusiness( {
			page,
			keyword,
			category,
			businessTaskID,
			order_by,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		} )
    }

	componentDidMount(){
		loadBusiness()
		this.props.loadBusinessTask( {} )
	}

    render(){
		let { business, businessTask, categories }    = this.props

		let {
			page,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		 } = this.state

		let allBusiness, allBusinessTask, allCategories, businessPagination;
		if(business && business.data){
			allBusiness        = business.data.length ? business.data : null
			businessPagination = business.pagination ? business.pagination : null
		}
		if(categories && categories.data){
			allCategories      = categories.data.length ? categories.data : null
		}
		if(businessTask && businessTask.data){
			allBusinessTask      = businessTask.data.length ? businessTask.data : null
		}

		return(
			<div>

				<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
					<h1 className="display-4">Business</h1>
					{/* <p className="lead">Quickly build an effective pricing table for your.</p> */}
				</div>

				<div className="mb-3">
					<div className="table-responsive">

					{ this.state.FilterAreaStatus ?

						<div className="section-filter">
							<label>
								<select
									className = "mr-3"
									name      = "category"
									onChange  = { this.changeAndLoad }
									>

									<option value="">All Category</option>

									{ allCategories ? allCategories.map( itemInfo => (
										<option value={ itemInfo.CategoryID } key={ itemInfo.CategoryID }>{ itemInfo.category }</option>
									) ) : '' }
									<option value="non">Uncategorized</option>

								</select>
							</label>
							<label>
								<select
									className = "mr-3"
									name      = "businessTaskID"
									onChange  = { this.changeAndLoad }
									>

									<option value="">-Select Task-</option>

									{ allBusinessTask ? allBusinessTask.map( itemInfo => (
										<option value={ itemInfo.TaskID } key={ itemInfo.TaskID }>{ itemInfo.task_title }</option>
									) ) : '' }

								</select>
							</label>
							<label className = "mr-2">
								<select
									className = "mr-3"
									name      = "order_by"
									onChange  = { this.changeAndLoad }
									>

									<option value="">Order by</option>
									<option value="biz_date">Date added</option>
									<option value="biz_title">Business Name</option>
									<option value="biz_website">Website</option>

								</select>
							</label>
							<label className = "mr-2">
								<input
									name      = "has_website"
									checked   = { has_website }
									onChange  = { this.changeAndLoad }
									value     = { has_website }
									type      = "checkbox"
									className = "mr-1"/>
								Has website
							</label>
							<label className = "mr-2">
								<input
									name      = "has_email"
									checked   = { has_email }
									onChange  = { this.changeAndLoad }
									value     = { has_email }
									type      = "checkbox"
									className = "mr-1"/>
								Has Email
							</label>
							<label className = "mr-2">
								<input
									name      = "has_phone"
									checked   = { has_phone }
									onChange  = { this.changeAndLoad }
									value     = { has_phone }
									type      = "checkbox"
									className = "mr-1"/>
								Has Phone
							</label>
							<label className = "mr-2">
								<input
									name      = "is_modern"
									checked   = { is_modern }
									onChange  = { this.changeAndLoad }
									value     = { is_modern }
									type      = "checkbox"
									className = "mr-1"/>
								Modern
							</label>
							<label className = "mr-2">
								<input
									name      = "is_responsive"
									checked   = { is_responsive }
									onChange  = { this.changeAndLoad }
									value     = { is_responsive }
									type      = "checkbox"
									className = "mr-1"/>
								Responsive
							</label>
							<label className = "mr-2">
								<input
									name      = "has_appointment"
									checked   = { has_appointment }
									onChange  = { this.changeAndLoad }
									value     = { has_appointment }
									type      = "checkbox"
									className = "mr-1"/>
								Appointment
							</label>
							<label className = "mr-2">
								<input
									name      = "has_contact_form"
									checked   = { has_contact_form }
									onChange  = { this.changeAndLoad }
									value     = { has_contact_form }
									type      = "checkbox"
									className = "mr-1"/>
								Contact Form
							</label>
							<input type      = "text"
								onChange  = { this.changeAndLoad }
								name      = "keyword"
								className = "ml-3" placeholder = "Search..."/>
								<br/>
							<label className = "mr-2">
								<input
									name      = "has_not_email"
									checked   = { has_not_email }
									onChange  = { this.changeAndLoad }
									value     = { has_not_email }
									type      = "checkbox"
									className = "mr-1"/>
								Without Email
							</label>
							<label className = "mr-2">
								<input
									name      = "has_not_phone"
									checked   = { has_not_phone }
									onChange  = { this.changeAndLoad }
									value     = { has_not_phone }
									type      = "checkbox"
									className = "mr-1"/>
								Without Phone
							</label>
						</div>

					:false }

					<div className="section-actions text-right">
						<button onClick={ this.collapseFilterArea }>Filter</button>
						<button onClick={ this.openAddBusinessModal }>New</button>
						{
							this.state.addBusinessModalOpen == true ?
								<NewBusinessModal
									isOpen = { this.state.addBusinessModalOpen }
									close  = { this.closeAddBusinessModal }
								/>
							:null
						}

						<button onClick={ () => this.loadBusiness() }>Reload</button>
					</div>

						<table className="table table-striped table-sm">
							<thead>
								<tr>
									<th>Added</th>
									<th>Title</th>
									<th>Address</th>
									<th>Website</th>
									<th>Email/Phone</th>
									<th className="action-column"></th>
								</tr>
							</thead>
							<tbody>
								{
									allBusiness ? allBusiness.map( business => (
											<tr key={ business.BusinessID }>
												<td> { business.biz_date } </td>
												<td>
													{ business.biz_title }
													{
														business.biz_address?
														 <a href={ 'https://maps.google.com/?q='+business.biz_title+', '+business.biz_address +', '+ business.biz_zip_code } target="_blank">Open Map</a>
														:null
													}
													{
														business.biz_listing_url?
														<>
															<br />
															<a href={ business.biz_listing_url } target="_blank">Data Source</a>
														</>
														:null
													}
												</td>
												<td>
													{
														business.biz_address +', '+ business.biz_zip_code
													}
												</td>
												<td>
													{
														business.biz_website?
														<a href={ business.biz_website } target="_blank">Website</a>
														:null
													}
												</td>
												<td>
													<span>{ business.biz_email }</span><br/>
													{ business.biz_phone? business.biz_phone :null }
												</td>
												<td className="text-right">
													<button
														onClick={ () => this.deleteHandler( business.BusinessID ) }

														className="btn btn-xs btn-danger mr-1"><i className="fa fa-trash" aria-hidden="true"></i></button>

													<button
														type      = "button"
														onClick   = { () => this.openEditBusinessModal(business) }
														className = "btn btn-xs btn-info mr-1">
															Edit
													</button>
													{
														this.state.editBusinessID == business.BusinessID ?

														<EditBusiness
															isOpen       = { this.state.editBusinessModalOpen }
															close        = { this.closeEditBusinessModal }
															BusinessID   = { business.BusinessID }
															businessInfo = { this.state.editBusiness }
														/>
														: null
													}

													{
														(business.ShowTaskAction)?
														<button
															onClick={ () => this.taskCompleteHandler( {BusinessID:business.BusinessID,  TaskID:business.TaskID}) }
															className="btn btn-xs btn-primary mr-1"><i className="fa fa-tick" aria-hidden="true"></i> Done</button>

														:''
													}

												</td>
											</tr>
										) )
									: ''
								}
							</tbody>
						</table>
						{
							businessPagination ?
							<div className="section-pagination">
								<nav
									className="pagination-nav"
									aria-label="...">
									<ReactPaginate
										previousLabel         = {'previous'}
										nextLabel             = {'next'}
										breakLabel            = {'...'}
										breakClassName        = {'break-me'}
										pageCount             = { businessPagination.page_last }
										initialPage           = { ( businessPagination.page - 1 ) }
										marginPagesDisplayed  = {2}
										pageRangeDisplayed    = {5}
										containerClassName    = {'pagination'}
										previousClassName     = {'page-item'}
										nextClassName         = {'page-item'}
										pageClassName         = {'page-item'}
										subContainerClassName = {'pages pagination'}
										activeClassName       = {'active'}
										pageLinkClassName     = {'page-link'}
										previousLinkClassName = {'page-link'}
										nextLinkClassName     = {'page-link'}
										onPageChange          = {this.handlePagination}
										/>
								</nav>

								<input
									className = "pagination-custom"
									onChange  = { this.changeAndLoad }
									type      = "number" name = "page" value = { page }/>

								<font>Showing { businessPagination.data_start } to { businessPagination.data_end } out of { businessPagination.data_total }</font>
							</div>

							:''
						}
					</div>
				</div>

			</div>
        )
    }
}

const mapStateToProps = state => ({
    categories  : state.category,
    business    : state.business,
    businessTask: state.businessTask
})
const mapDispatchToProps = ({
	logout,
	loadBusiness,
	loadBusinessTask,
	completeBusinessTask,
	EditBusiness,
	deleteBusiness,
	NewBusinessModal,
	ReactPaginate,
})

export default withRouter(connect( mapStateToProps, mapDispatchToProps )(Business))