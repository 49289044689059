import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { register } from '../store/actions/authActions'


// const Register = props => ( <h1>I am Register</h1> )
class Register extends React.Component {
    state = {
        firstName      : '',
        lastName       : '',
        username       : '',
        email          : '',
        password       : '',
        confirmPassword: '',
        error          : {},
    }

    static getDerivedStateFromProps(nextProps, prevProps){
        if(JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)){
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = event => {
        event.preventDefault()
        let { firstName, lastName, username, email, password, confirmPassword }  = this.state
        this.props.register( { firstName, lastName, username, email, password, confirmPassword }, this.props.history )
    }

    render(){
        let { firstName, lastName, username, email, password, confirmPassword } = this.state

        return(
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1>Registration first</h1>
                    <form onSubmit={this.submitHandler}>
                        <div className="form-group">
                            <label>First Name:</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Enter your first name"
                                name        = "firstName"
                                id          = "firstName"
                                onChange    = { this.changeHandler }
                                value       = { firstName }
                            />
                        </div>
                        <div className="form-group">
                            <label>Last Name:</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Enter your last name"
                                name        = "lastName"
                                id          = "lastName"
                                onChange    = { this.changeHandler }
                                value       = { lastName }
                            />
                        </div>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Enter username"
                                name        = "username"
                                id          = "username"
                                onChange    = { this.changeHandler }
                                value       = { username }
                            />
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type        = "email"
                                className   = "form-control"
                                placeholder = "Enter your email"
                                name        = "email"
                                id          = "email"
                                onChange    = { this.changeHandler }
                                value       = { email }
                            />
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input
                                type        = "password"
                                className   = "form-control"
                                placeholder = "Enter your password"
                                name        = "password"
                                id          = "password"
                                onChange    = { this.changeHandler }
                                value       = { password }
                            />
                        </div>
                        <div className="form-group">
                            <label>Confirm Password:</label>
                            <input
                                type        = "password"
                                className   = "form-control"
                                placeholder = "Retype password"
                                name        = "confirmPassword"
                                id          = "confirmPassword"
                                onChange    = { this.changeHandler }
                                value       = { confirmPassword }
                            />
                        </div>
                        <Link to="/login">Login</Link>

                        <button className="btn btn-primary d-block">Register</button>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect( mapStateToProps, {register} )(Register)