import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { updateAddress, resetMessage } from  '../../store/actions/addressActions'


class EditAddress extends React.Component{
    state = {
        ZipCode   : '',
        zip_status: '',
        error     : {},
        message   : '',
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.updateAddress( {
			ZipCode   : this.props.ZipCode,
			zip_status: this.state.zip_status
		} )
	}

	componentDidMount(){
		this.props.resetMessage()
        this.setState({
            ZipCode: this.props.editAddress.ZipCode,
            zip_status: this.props.editAddress.zip_status,
        })
	}

    changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let {
			ZipCode,
			zip_status
		} = this.state

		let error = {}

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h3>
						Edit {ZipCode}
					</h3>
					<form onSubmit={this.submitHandler} className="">
						<input type="hidden" name="CategoryID" value={ this.props.CategoryID }/>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="zip_status">Status:</label>
									<select
										className   = {
											( error.zip_status ? "form-control is-invalid" : "form-control" )
										}
										name     = "zip_status"
										id       = "zip_status"
										onChange = { this.changeHandler }
										value    = { zip_status }>
										<option value="active">Active</option>
										<option value="pending">Pending</option>
										<option value="completed">Completed</option>
									</select>
									{ error.zip_status &&(
										<div
											className="invalid-feedback">
											{ error.zip_status }
										</div>
									) }
								</div>
							</div>
						</div>

						<div className="form-group has-error">
							<button
								className="btn btn-primary mr-4 mb-2 is-invalid">Update</button>

								{ this.props.address.success?
									<font className="text-success">
										{ this.props.address.success }
									</font>
									:null
								}

								{ error.error &&(
									<font className="invalid-feedback">
										{ error.error }
									</font>
								) }
						</div>
					</form>
				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    address: state.address
})
export default connect( mapStateToProps, { updateAddress, resetMessage } )( EditAddress )
