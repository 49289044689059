import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate';


import { logout } from '../store/actions/authActions'
import { addBusinessTask, loadBusinessTask, deleteBusinessTask } from '../store/actions/businessActions'

import EditBusiness from '../components/business/EditBusiness'
import NewTaskModal from '../components/business/NewTaskModal'


class TaskPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			FilterAreaStatus    : false,
			addBusinessModalOpen: false,

            editBusinessModalOpen: false,
            editBusiness         : false,
            editBusinessID       : false,

			category        : false,
			order_by        : false,
			keyword         : false,
			page            : 1,
			has_website     : false,
			has_contact_form: false,
			is_modern       : false,
			is_responsive   : false,
			has_email       : false,
			has_phone       : false,

			has_contact_form: false,
			has_not_phone   : false,
			has_not_email   : false,

            error                : {},
        }
	}

	collapseFilterArea = () => {
		this.setState({
			FilterAreaStatus : this.state.FilterAreaStatus? false : true
		})
	}

	openAddBusinessModal = () => {
		this.setState({
			addBusinessModalOpen : true
		})
	}
	closeAddBusinessModal = () => {
		this.setState({
			addBusinessModalOpen : false
		})
	}

	openEditBusinessModal = business => {
		this.setState({
			...this.state,
			editBusiness         : business,
			editBusinessID       : business.BusinessID,
			editBusinessModalOpen: true,
		})
	}
	closeEditBusinessModal = () => {
		this.setState({
			...this.state,
			editBusiness         : false,
			editBusinessID       : false,
			editBusinessModalOpen: false
		})
	}

    deleteHandler = TaskID => {
        this.props.deleteBusinessTask( {TaskID} )
	}

	changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	changeAndLoad = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			}, () => {
				this.loadBusinessTask()
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			}, () => {
				this.loadBusinessTask()
			})
		}
    }

    submitHandler = event => {
        event.preventDefault()
	}

    handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.loadBusinessTask()
		});
    }
    loadBusinessTask() {
		let { page, keyword,
			category,
			order_by,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		} = this.state

		this.props.loadBusinessTask( {
			page,
			keyword,
			category,
			order_by,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		} )
    }

	componentDidMount(){
		loadBusinessTask()
	}

    render(){
		let { businessTask, categories }    = this.props

		let {
			page,
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		 } = this.state

		let allTasks, allCategories, tasksPagination;
		if(businessTask && businessTask.data){
			allTasks           = businessTask.data.length ? businessTask.data : null
			tasksPagination = businessTask.pagination ? businessTask.pagination : null
		}
		if(categories && categories.data){
			allCategories      = categories.data.length ? categories.data : null
		}

		return(
			<div>

				<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
					<h1 className="display-4">Tasks</h1>
					{/* <p className="lead">Quickly build an effective pricing table for your.</p> */}
				</div>

				<div className="mb-3">
					<div className="table-responsive">

					{ this.state.FilterAreaStatus ?

						<div className="section-filter">
							<label>
								<select
									className = "mr-3"
									name      = "category"
									onChange  = { this.changeAndLoad }
									>

									<option value="">All Category</option>

									{ allCategories ? allCategories.map( itemInfo => (
										<option value={ itemInfo.CategoryID } key={ itemInfo.CategoryID }>{ itemInfo.category }</option>
									) ) : '' }
									<option value="non">Uncategorized</option>

								</select>
							</label>
							<label className = "mr-2">
								<select
									className = "mr-3"
									name      = "order_by"
									onChange  = { this.changeAndLoad }
									>

									<option value="">Order by</option>
									<option value="biz_date">Date added</option>
									<option value="biz_title">Business Name</option>
									<option value="biz_website">Website</option>

								</select>
							</label>
							<input type      = "text"
								onChange  = { this.changeAndLoad }
								name      = "keyword"
								className = "ml-3" placeholder = "Search..."/>
						</div>

					:false }

					<div className="section-actions text-right">
						<button onClick={ this.collapseFilterArea }>Filter</button>
						<button onClick={ this.openAddBusinessModal }>New</button>
						{
							this.state.addBusinessModalOpen == true ?
								<NewTaskModal
									isOpen = { this.state.addBusinessModalOpen }
									close  = { this.closeAddBusinessModal }
								/>
							:null
						}

						<button onClick={ () => this.loadBusinessTask() }>Reload</button>
					</div>

						<table className="table table-striped table-sm">
							<thead>
								<tr>
									<th>Title</th>
									<th>Category</th>
									<th>Source</th>
									<th className="action-column"></th>
								</tr>
							</thead>
							<tbody>
								{
									allTasks ? allTasks.map( business => (
											<tr key={ business.TaskID }>
												<td>
													{ business.task_title } <br/>
													<small>{ business.task_date }</small>
												</td>
												<td> { business.task_category } </td>
												<td> { business.task_source } </td>
												<td className="text-right">
													<button
														onClick={ () => this.deleteHandler( business.TaskID ) }

														className="btn btn-xs btn-danger mr-1"><i className="fa fa-trash" aria-hidden="true"></i></button>
													<button
														type      = "button"
														onClick   = { () => this.openEditBusinessModal(business) }
														className = "btn btn-xs btn-info mr-1">
															Edit
													</button>

														{
															this.state.editBusinessID == business.BusinessID ?

															<EditBusiness
																isOpen       = { this.state.editBusinessModalOpen }
																close        = { this.closeEditBusinessModal }
																BusinessID   = { business.BusinessID }
																businessInfo = { this.state.editBusiness }
															/>
															: null
														}


												</td>
											</tr>
										) )
									: ''
								}
							</tbody>
						</table>
						{
							tasksPagination ?
							<div className="section-pagination">
								<nav
									className="pagination-nav"
									aria-label="...">
									<ReactPaginate
										previousLabel         = {'previous'}
										nextLabel             = {'next'}
										breakLabel            = {'...'}
										breakClassName        = {'break-me'}
										pageCount             = { tasksPagination.page_last }
										initialPage           = { ( tasksPagination.page - 1 ) }
										marginPagesDisplayed  = {2}
										pageRangeDisplayed    = {5}
										containerClassName    = {'pagination'}
										previousClassName     = {'page-item'}
										nextClassName         = {'page-item'}
										pageClassName         = {'page-item'}
										subContainerClassName = {'pages pagination'}
										activeClassName       = {'active'}
										pageLinkClassName     = {'page-link'}
										previousLinkClassName = {'page-link'}
										nextLinkClassName     = {'page-link'}
										onPageChange          = {this.handlePagination}
										/>
								</nav>

								<input
									className = "pagination-custom"
									onChange  = { this.changeAndLoad }
									type      = "number" name = "page" value = { page }/>

								<font>Showing { tasksPagination.data_start } to { tasksPagination.data_end } out of { tasksPagination.data_total }</font>
							</div>

							:''
						}
					</div>
				</div>

			</div>
        )
    }
}

const mapStateToProps = state => ({
    categories  : state.category,
    businessTask: state.businessTask
})
const mapDispatchToProps = ({
	logout,
	addBusinessTask,
	loadBusinessTask,
	EditBusiness,
	deleteBusinessTask,
	NewTaskModal,
	ReactPaginate,
})

export default withRouter(connect( mapStateToProps, mapDispatchToProps )(TaskPage))