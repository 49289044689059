export const SET_USER      = 'SET_USER'
export const USERS_ERROR   = 'USERS_ERROR'

export const LOAD_CATEGORY   = 'LOAD_CATEGORY'
export const ADD_CATEGORY    = 'ADD_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'


export const UPDATE_RESET    = 'UPDATE_RESET'

export const NEW_BUSINESS    = 'NEW_BUSINESS'
export const LOAD_BUSINESS   = 'LOAD_BUSINESS'
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const DELETE_BUSINESS = 'DELETE_BUSINESS'

export const BUSINESS_TASK_LIST     = 'BUSINESS_TASK_LIST'
export const BUSINESS_TASK_NEW      = 'BUSINESS_TASK_NEW'
export const BUSINESS_TASK_UPDATE   = 'BUSINESS_TASK_UPDATE'
export const BUSINESS_TASK_DELETE   = 'BUSINESS_TASK_DELETE'
export const BUSINESS_TASK_COMPLETE = 'BUSINESS_TASK_COMPLETE'

export const LOAD_STATES   = 'LOAD_STATES'

export const LOAD_ADDRESS   = 'LOAD_ADDRESS'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'



export const LOAD_SOURCE   = 'LOAD_SOURCE'
export const ADD_SOURCE    = 'ADD_SOURCE'
export const DELETE_SOURCE = 'DELETE_SOURCE'
export const UPDATE_SOURCE = 'UPDATE_SOURCE'