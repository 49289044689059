import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../store/actions/authActions'

class Login extends React.Component {
    state = {
        user    : '',
        password: '',
        error   : {},
    }

    static getDerivedStateFromProps(nextProps, prevProps){
        if(JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)){
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = event => {
        event.preventDefault()
        let {user, password}  = this.state
        this.props.login( {user, password}, this.props.history )
    }

    render(){
		let { user, password, error } = this.state
        return(
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1>Login here</h1>
                    <form onSubmit={this.submitHandler} className="">
                        <div className="form-group has-error">
                            <label className="control-label">user:</label>
                            <input
                                type        = "text"
                                className   = {
                                    ( error.login ? "form-control is-invalid" : "form-control" )
                                }
                                placeholder = "Enter your user"
                                name        = "user"
                                id          = "user"
                                onChange    = { this.changeHandler }
                                value       = { user }
                            />
                            { error.login &&(
                                <div className="invalid-feedback">
                                    { error.login }
                                </div>
                            ) }
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input
                                type        = "password"
                                className   = {
                                    ( error.login ? "form-control is-invalid" : "form-control" )
                                }
                                placeholder = "Enter your password"
                                name        = "password"
                                id          = "password"
                                onChange    = { this.changeHandler }
                                value       = { password }
                            />
                            { error.login &&(
                                <div className="invalid-feedback">
                                    { error.login }
                                </div>
                            ) }
                        </div>
                        <button className="btn btn-primary d-block mb-2">Login</button>
                        <Link to="/register" className="">Register</Link>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})
export default connect( mapStateToProps, {login} )(Login)