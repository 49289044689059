import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'

class LoginFirst extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user    : '',
            password: '',
            error   : {},
		}
    }

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitHandler = event => {
		event.preventDefault()

        this.props.logout( this.props.history )
    }

    render(){
        // let { user, password, error } = this.state
        return(

			<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
				<h1 className="display-4">Login First</h1>
				<p className="lead">You must need to login for get access on this page.</p>
			</div>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})
const mapDispatchToProps = ({
	// logout
})
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( LoginFirst ) )