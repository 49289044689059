import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { addCategory } from  '../../store/actions/categoryActions'


class AddNewCategory extends React.Component{
    state = {
        category_name  : '',
        category_parent: '',
        error          : {},
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.addCategory( { category: this.state.category_name, parent: this.state.category_parent } )
	}

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let { category_name } = this.state
		let { error }         = this.props.category

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h1>Add new category</h1>
					<form onSubmit={this.submitHandler} className="">
						<div className="form-group has-error">
							<label className="control-label">Category:</label>
							<input
								type        = "text"
								className   = {
									( error.category ? "form-control is-invalid" : "form-control" )
								}
								placeholder = "Enter category name"
								name        = "category_name"
								id          = "category"
								onChange    = { this.changeHandler }
								value       = { category_name }
							/>
							{ error.category &&(
								<div
									className="invalid-feedback">
									{ error.category }
								</div>
							) }
						</div>
						<div className="form-group has-error">
							<button
								className="btn btn-primary d-block mb-2 is-invalid">Submit</button>
								{ error.error &&(
									<div className="invalid-feedback">
										{ error.error }
									</div>
								) }
						</div>
					</form>
				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    category: state.category
})
export default connect( mapStateToProps, { addCategory } )(AddNewCategory)
