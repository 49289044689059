import axios from 'axios'
import * as Types from './types'

export const getStates = () => dispatch => {
	axios.post('/locations/states')
		.then((res) => {
			dispatch({
				type: Types.LOAD_STATES,
				payload: {
					states: res.data,
					error : {}
				}
			})
		})
		.catch(error => {
			console.log('state error', error)
		})
}