import axios from 'axios'
import * as Types from './types'

export const loadSource = ( data ) => dispatch => {
	axios.post('business/data-source/list', data )
		.then((res) => {
			let responseData = res.data

			dispatch({
				type   : Types.LOAD_SOURCE,
				payload: {
					category: responseData,
					error   : {}
				}
			})
		})
		.catch(error => {
			// console.log('error', typeof(error));
			// console.log('error', error);

			// dispatch({
			// 	type: Types.USERS_ERROR,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}

export const addSource = category => dispatch => {
	axios.post('business/data-source/add', category)
		.then((res) => {
			dispatch({
				type   : Types.ADD_SOURCE,
				payload: {
					category: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.ADD_SOURCE,
				payload: {
					error: error.response.data
				}
			})
		})
}

export const updateSource = category => dispatch => {
	axios.post('business/data-source/update', category)
		.then((res) => {
			dispatch({
				type   : Types.UPDATE_SOURCE,
				payload: {
					source: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.UPDATE_SOURCE,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const removeSource = postData => dispatch => {
	axios.delete('business/data-source/delete', {data: postData} )
		.then((res) => {
			console.log('res', res)
			dispatch({
				type   : Types.DELETE_SOURCE,
				payload: {
					source: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log('error', error)
			// dispatch({
			// 	type   : Types.DELETE_SOURCE,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}

export const resetMessage = () => dispatch => {
	dispatch({
		type   : Types.UPDATE_RESET,
		payload: {
			message: null
		}
	})
}