import React from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';


import { logout } from '../store/actions/authActions'
import { loadCategory, removeCategory } from '../store/actions/categoryActions'
import { withRouter } from 'react-router-dom'
import AddNewCategory from '../components/category/AddNewCategory'
import EditCategory from '../components/category/EditCategory'


class Category extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			addCategoryModalOpen : false,

            editCategoryModalOpen: false,
            editCategory         : false,
            editCategoryID       : false,

            error                : {},
        }
	}

	openAddCategoryModal = () => {
		this.setState({
			addCategoryModalOpen : true
		})
	}
	closeAddCategoryModal = () => {
		this.setState({
			addCategoryModalOpen : false
		})
	}
	openEditCategoryModal = category => {
		this.setState({
			editCategory         : category,
			editCategoryID       : category.CategoryID,
			editCategoryModalOpen: true,
		})
	}
	closeEditCategoryModal = () => {
		this.setState({
			editCategory         : false,
			editCategoryID       : false,
			editCategoryModalOpen: false
		})
	}

	changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }

    submitHandler = event => {
        event.preventDefault()
	}
    deleteHandler = CategoryID => {
        this.props.removeCategory( {CategoryID} )
    }
    handlePagination = data => {
		this.props.loadCategory( { page: ( data.selected + 1 ) } )
    }

	componentDidMount(){
		this.props.loadCategory()
	}

    render(){
		let { categories } = this.props
		let allCategories, categoryPagination;
		if(categories.data){
			allCategories      = categories.data.length ? categories.data : null
			categoryPagination = categories.pagination ? categories.pagination : null
		}

		return(
			<div>

				<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
					<h1 className="display-4">Category</h1>
					<p className="lead">Quickly build an effective pricing table for your.</p>
				</div>

				<div className="mb-3">
					<div className="table-responsive">
						<button onClick={ this.openAddCategoryModal }>Create new</button>
						<AddNewCategory
							isOpen = { this.state.addCategoryModalOpen }
							close  = { this.closeAddCategoryModal }
						/>
						<table className="table table-striped table-sm">
							<thead>
								<tr>
									<th>Category</th>
									<th>Date added</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									allCategories ? allCategories.map( category => (
											<tr key={ category.CategoryID }>
												<td> { category.category } </td>
												<td> { category.date } </td>
												<td className="text-right">
													<button
														type      = "button"
														onClick   = { () => this.openEditCategoryModal(category) }
														className = "btn btn-xs btn-info mr-1">Edit</button>
														{

															this.state.editCategoryID == category.CategoryID ?

															<EditCategory
																isOpen       = { this.state.editCategoryModalOpen }
																close        = { this.closeEditCategoryModal }
																CategoryID   = { category.CategoryID }
																categoryInfo = { this.state.editCategory }
															/>
															: null
														}

													<button
														type      = "button"
														onClick   = { () => this.deleteHandler(category.CategoryID) }
														className = "btn btn-xs btn-danger">Delete</button>

												</td>
											</tr>
										) )
									: ''
								}
							</tbody>
						</table>
						{
							categoryPagination ?
							<nav aria-label="...">
								<ReactPaginate
									previousLabel          = {'previous'}
									nextLabel              = {'next'}
									breakLabel             = {'...'}
									breakClassName         = {'break-me'}
									pageCount              = { categoryPagination.page_last }
									// initialPage            = { 1 }
									initialPage           = { ( categoryPagination.page - 1 ) }
									marginPagesDisplayed  = {2}
									pageRangeDisplayed    = {5}
									containerClassName    = {'pagination'}
									previousClassName     = {'page-item'}
									nextClassName         = {'page-item'}
									pageClassName         = {'page-item'}
									subContainerClassName = {'pages pagination'}
									activeClassName       = {'active'}
									pageLinkClassName     = {'page-link'}
									previousLinkClassName = {'page-link'}
									nextLinkClassName     = {'page-link'}
									onPageChange          = {this.handlePagination}
									/>
									Showing { categoryPagination.data_start } to { categoryPagination.data_end } out of { categoryPagination.data_total }
							</nav>
							:''
						}
					</div>
				</div>

			</div>
        )
    }
}

const mapStateToProps = state => ({
    auth      : state.auth,
    categories: state.category
})
const mapDispatchToProps = ({
	logout,
	loadCategory,
	AddNewCategory,
	EditCategory,
	removeCategory,
	ReactPaginate,
})

export default withRouter(connect( mapStateToProps, mapDispatchToProps )(Category))