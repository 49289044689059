import React from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router-dom'

import { loadSource, addSource, updateSource, removeSource } from '../store/actions/sourceActions'

import NewSourceModal from '../components/source/NewSourceModal'
import EditSourceModal from '../components/source/EditSourceModal'


class Source extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			addSourceModalOpen : false,

            editSourceModalOpen: false,
            editSource         : false,
            editSourceID       : false,

            error                : {},
        }
	}

	openAddSourceModal = () => {
		this.setState({
			addSourceModalOpen : true
		})
	}
	closeAddSourceModal = () => {
		this.setState({
			addSourceModalOpen : false
		})
	}
	openEditSourceModal = curItem => {
		this.setState({
			editSource         : curItem,
			editSourceID       : curItem.DSID,
			editSourceModalOpen: true,
		})
	}
	closeEditSourceModal = () => {
		this.setState({
			editSource         : false,
			editSourceID       : false,
			editSourceModalOpen: false
		})
	}

	changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }

    submitHandler = event => {
        event.preventDefault()
	}
    deleteHandler = DSID => {
        this.props.removeSource( {DSID} )
    }
    handlePagination = data => {
		this.props.loadSource( { page: ( data.selected + 1 ) } )
    }

	componentDidMount(){
		// this.props.loadSource()
	}

    render(){
		let { source } = this.props
		let allSources, sourcePagination;
		if(source.data){
			allSources       = source.data.length ? source.data : null
			sourcePagination = source.pagination ? source.pagination : null
		}

		return(
			<div>

				<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
					<h1 className="display-4">Data source</h1>
					{/* <p className="lead">Quickly build an effective pricing table for your.</p> */}
				</div>

				<div className="mb-3">
					<div className="table-responsive">
						<button onClick={ this.openAddSourceModal }>Create new</button>
						<NewSourceModal
							isOpen = { this.state.addSourceModalOpen }
							close  = { this.closeAddSourceModal }
						/>
						<table className="table table-striped table-sm">
							<thead>
								<tr>
									<th>Title</th>
									<th>Details</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									allSources ? allSources.map( curItem => (
											<tr key={ curItem.DSID }>
												<td> { curItem.ds_title } </td>
												<td> { curItem.ds_details } </td>
												<td className="text-right">
													<button
														type      = "button"
														onClick   = { () => this.openEditSourceModal(curItem) }
														className = "btn btn-xs btn-info mr-1">Edit</button>
														{

															this.state.editSourceID == curItem.DSID ?

															<EditSourceModal
																isOpen     = { this.state.editSourceModalOpen }
																close      = { this.closeEditSourceModal }
																DSID       = { curItem.DSID }
																sourceInfo = { this.state.editSource }
															/>
															: null
														}

													<button
														type      = "button"
														onClick   = { () => this.deleteHandler(curItem.DSID) }
														className = "btn btn-xs btn-danger">Delete</button>

												</td>
											</tr>
										) )
									: ''
								}
							</tbody>
						</table>
						{
							sourcePagination ?
							<nav aria-label="...">
								<ReactPaginate
									previousLabel          = {'previous'}
									nextLabel              = {'next'}
									breakLabel             = {'...'}
									breakClassName         = {'break-me'}
									pageCount              = { sourcePagination.page_last }
									initialPage           = { ( sourcePagination.page - 1 ) }
									marginPagesDisplayed  = {2}
									pageRangeDisplayed    = {5}
									containerClassName    = {'pagination'}
									previousClassName     = {'page-item'}
									nextClassName         = {'page-item'}
									pageClassName         = {'page-item'}
									subContainerClassName = {'pages pagination'}
									activeClassName       = {'active'}
									pageLinkClassName     = {'page-link'}
									previousLinkClassName = {'page-link'}
									nextLinkClassName     = {'page-link'}
									onPageChange          = {this.handlePagination}
									/>
									Showing { sourcePagination.data_start } to { sourcePagination.data_end } out of { sourcePagination.data_total }
							</nav>
							:''
						}
					</div>
				</div>

			</div>
        )
    }
}

const mapStateToProps = state => ({
    source: state.source
})
const mapDispatchToProps = ({
	loadSource,
	addSource,
	updateSource,
	removeSource,
	ReactPaginate,
})

export default withRouter(connect( mapStateToProps, mapDispatchToProps )(Source))