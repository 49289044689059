import axios from 'axios'
import * as Types from './types'

export const loadCategory = ( data ) => dispatch => {
	axios.post('category/list', data )
		.then((res) => {
			let categoryData = res.data

			dispatch({
				type   : Types.LOAD_CATEGORY,
				payload: {
					category: categoryData,
					error   : {}
				}
			})
		})
		.catch(error => {
			// console.log('error', typeof(error));
			// console.log('error', error);

			// dispatch({
			// 	type: Types.USERS_ERROR,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}


export const addCategory = category => dispatch => {
	axios.post('category/add', category)
		.then((res) => {
			dispatch({
				type   : Types.ADD_CATEGORY,
				payload: {
					category: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.ADD_CATEGORY,
				payload: {
					error: error.response.data
				}
			})
		})
}
export const updateCategory = category => dispatch => {
	axios.post('category/update', category)
		.then((res) => {
			dispatch({
				type   : Types.UPDATE_CATEGORY,
				payload: {
					category: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.UPDATE_CATEGORY,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const removeCategory = postData => dispatch => {
	axios.delete('category/delete', {data: postData} )
		.then((res) => {
			console.log('res', res)
			dispatch({
				type   : Types.DELETE_CATEGORY,
				payload: {
					category: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log('error', error)
			// dispatch({
			// 	type   : Types.DELETE_CATEGORY,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}