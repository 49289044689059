import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from '../pages/Home'
import Login from '../pages/Login'
import Register from '../pages/Register'
import Address from '../pages/Address'
import Category from '../pages/Category'
import TaskPage from '../pages/TaskPage'
import Business from '../pages/Business'
import Source from '../pages/Source'


import LoginFirst from './default/loginFirst'


import { connect } from 'react-redux'

import { isLoggedin } from '../store/actions/authActions'

import { getStates } from '../store/actions/stateActions'
import { loadCategory } from '../store/actions/categoryActions'
import { loadSource } from '../store/actions/sourceActions'

import setAuthToken from '../store/utils/setAuthToken'
import configureAxios from '../store/utils/configureAxios'


import PrimaryMenu from './menu/headerPrimaryMenu'
import FooterPrimary from './footer/footerPrimary'



class App extends React.Component {
  state = {
      user    : '',
      password: '',
      error   : {},
  }
  componentWillMount(){
	configureAxios() // Run Basic Axios Configure

	const authToken = localStorage.getItem( 'authToken' )
	if(authToken){
		setAuthToken( authToken )

		this.props.isLoggedin( authToken, this.props.history )
		this.props.getStates()
		this.props.loadCategory()
		this.props.loadSource()
	}

  }

  componentDidMount(){
  }

  render(){

    // let { user, password, error } = this.state
    let { isAuthenticated } = this.props.auth
      return(
        <BrowserRouter>
          <div className="container">

				<PrimaryMenu />

				<Switch>
					<Route path="/" exact component={ Home } />
					<Route path="/login" component={ Login } />
					<Route path="/register" component={ isAuthenticated ? Register : LoginFirst } />
					<Route path="/address" component={ isAuthenticated ? Address : LoginFirst } />
					<Route path="/category" component={ isAuthenticated ? Category : LoginFirst } />
					<Route path="/tasks" component={ isAuthenticated ? TaskPage : LoginFirst } />
					<Route path="/business" component={ isAuthenticated ? Business : LoginFirst } />
					<Route path="/source" component={ isAuthenticated ? Source : LoginFirst } />
				</Switch>

				<div className="container">
					<FooterPrimary />
				</div>
          </div>
        </BrowserRouter>
      )
  }
}

// export default App;

const mapStateToProps = state => ({
  auth: state.auth
})
const mapDispatchToProps = ({
	isLoggedin,
	PrimaryMenu,
	FooterPrimary,
	getStates,
	loadCategory,
	loadSource
})
export default connect( mapStateToProps, mapDispatchToProps )(App)