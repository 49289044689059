import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { addSource } from  '../../store/actions/sourceActions'


class NewSourceModal extends React.Component{
    state = {
        ds_title  : '',
        ds_details: '',
        error     : {},
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.addSource( { ds_title: this.state.ds_title, ds_details: this.state.ds_details } )
	}

    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let { ds_title, ds_details } = this.state
		let { error }                = this.props.source

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h1>Add new source</h1>
					<form onSubmit={this.submitHandler} className="">

						<div className="form-group has-error">
							<label className="control-label">Title:</label>
							<input
								type        = "text"
								className   = {
									( error.ds_title ? "form-control is-invalid" : "form-control" )
								}
								placeholder = "Ex. google map"
								name        = "ds_title"
								id          = "ds_title"
								onChange    = { this.changeHandler }
								value       = { ds_title }
							/>
							{ error.ds_title &&(
								<div
									className="invalid-feedback">
									{ error.ds_title }
								</div>
							) }
						</div>
						<div className="form-group has-error">
							<label className="control-label">Details:</label>
							<textarea
								type        = "text"
								className   = {
									( error.ds_details ? "form-control is-invalid" : "form-control" )
								}
								placeholder = "Ex. Collect from google map"
								name        = "ds_details"
								id          = "ds_details"
								onChange    = { this.changeHandler }
								value       = { ds_details }
							/>
							{ error.ds_details &&(
								<div
									className="invalid-feedback">
									{ error.ds_details }
								</div>
							) }
						</div>

						<div className="form-group has-error">
							<button
								className="btn btn-primary d-block mb-2 is-invalid">Submit</button>
								{ error.error &&(
									<div className="invalid-feedback">
										{ error.error }
									</div>
								) }
						</div>
					</form>
				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    source: state.source
})
const mapDispatchToProps = ({
    addSource
})
export default connect( mapStateToProps, mapDispatchToProps )(NewSourceModal)
