import axios from 'axios'
import * as Types from './types'

export const loadAddress = ( data ) => dispatch => {
	axios.post('locations/list', data )

	.then((res) => {
			let addressData = res.data

			dispatch({
				type   : Types.LOAD_ADDRESS,
				payload: {
					address: addressData,
					error   : {}
				}
			})
		})
		.catch(error => {
			// console.log('error', typeof(error));
			// console.log('error', error);

			// dispatch({
			// 	type: Types.USERS_ERROR,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}

export const updateAddress = address => dispatch => {
	axios.post('locations/update', address)
		.then((res) => {
			dispatch({
				type   : Types.UPDATE_ADDRESS,
				payload: {
					address: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.UPDATE_ADDRESS,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const resetMessage = () => dispatch => {
	dispatch({
		type   : Types.UPDATE_RESET,
		payload: {
			message: null
		}
	})
}