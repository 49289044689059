import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import { logout } from '../store/actions/authActions'
import { loadAddress } from '../store/actions/addressActions'

import EditAddress from '../components/address/EditAddress'


class Address extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openEditAddressModal: false,
            editAddress         : false,
            editZipCode         : false,

			keyword         : false,
			zip_state       : false,
			zip_country     : false,
			zip_status      : false,
			page            : 1,
			has_website     : false,
			has_contact_form: false,
			is_modern       : false,
			is_responsive   : false,
			has_email       : false,
			has_phone       : false,

			has_contact_form: false,
			has_not_phone   : false,
			has_not_email   : false,

            error                : {},
        }
	}

	openEditAddressModal = business => {
		this.setState({
			...this.state,
			editAddress         : business,
			editZipCode         : business.ZipCode,
			openEditAddressModal: true,
		})
	}
	closeEditAddressModal = () => {
		this.setState({
			...this.state,
			editAddress         : false,
			editZipCode       : false,
			openEditAddressModal: false
		})
	}

	changeHandler = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			})
		}
    }
	changeAndLoad = event => {
		if(event.target.type == 'checkbox'){
			this.setState({
				[event.target.name]: event.target.checked
			}, () => {
				this.loadAddress()
			})
		}else{
			this.setState({
				[event.target.name]: event.target.value
			}, () => {
				this.loadAddress()
			})
		}
    }

    submitHandler = event => {
        event.preventDefault()
	}

    handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.loadAddress()
		});
    }
    loadAddress() {
		let { page, keyword,
			zip_status, zip_state, zip_country
		} = this.state

		this.props.loadAddress( {
			page,
			keyword,
			zip_status,
			zip_country,
			zip_state
		} )
    }

	componentDidMount(){
		loadAddress()
	}

    render(){
		let { address, states }    = this.props

		let {
			has_website,
			has_email,
			has_phone,
			is_modern,
			is_responsive,
			has_appointment,
			has_not_phone,
			has_contact_form,
			has_not_email
		 } = this.state

		let allAddress, businessPagination, allStates;

		if(address && address.data){
			allAddress        = address.data.length ? address.data : null
			businessPagination = address.pagination ? address.pagination : null
		}
		if(states && states.data){
			allStates = states.data.length ? states.data : null
		}

		return(
			<div>

				<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
					<h1 className="display-4">Address</h1>
				</div>

				<div className="mb-3">

					<div className="table-responsive">

					<select
						className = "mr-3"
						name      = "zip_status"
						onChange  = { this.changeAndLoad }
						>

						<option value="">All Status</option>
						<option value="active">Active</option>
						<option value="pending">Pending</option>
						<option value="completed">Completed</option>
					</select>

					<select
						className = "mr-3"
						name      = "zip_country"
						onChange  = { this.changeAndLoad }
						>

						<option value="US">United State</option>
						<option value="BD">Bangladesh</option>
					</select>

					<select
						className = "mr-3"
						name      = "zip_state"
						onChange  = { this.changeAndLoad }
						>

						<option value="">Any State</option>

						{ allStates ? allStates.map( stt => (
							<option value={ stt.StateID } key={ stt.StateID }>{ stt.state_name }</option>
						) ) : '' }

					</select>

					<input type      = "text"
					       onChange  = { this.changeAndLoad }
					       name      = "keyword"
					       className = "ml-3" placeholder = "Search..."/>

					<button onClick={ () => this.loadAddress() }>Reload</button>

						<table className="table table-striped table-sm">
							<thead>
								<tr>
									<th>Zip</th>
									<th>City</th>
									<th>State</th>
									<th>Map</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									allAddress ? allAddress.map( business => (
											<tr key={ business.ZipCode }>
												<td> { business.ZipCode } </td>
												<td>
													{ business.zip_city }
												</td>
												<td>
													{
														business.zip_state
													}
												</td>
												<td>
													{
														business.ZipCode?
														 <a href={ 'https://www.google.com/maps/search/salon in '+business.ZipCode+', USA' } target="_blank">Open Map</a>
														:null
													}
												</td>
												<td className="text-right">
													<button
														type      = "button"
														onClick   = { () => this.openEditAddressModal(business) }
														className = "btn btn-xs btn-info mr-1">Edit</button>
														{

															this.state.editZipCode == business.ZipCode ?

															<EditAddress
																isOpen      = { this.state.openEditAddressModal }
																close       = { this.closeEditAddressModal }
																ZipCode     = { business.ZipCode }
																editAddress = { this.state.editAddress }
															/>
															: null
														}


												</td>
											</tr>
										) )
									: ''
								}
							</tbody>
						</table>
						{
							businessPagination ?
							<nav aria-label="...">
								<ReactPaginate
									previousLabel          = {'previous'}
									nextLabel              = {'next'}
									breakLabel             = {'...'}
									breakClassName         = {'break-me'}
									pageCount              = { businessPagination.page_last }
									initialPage           = { ( businessPagination.page - 1 ) }
									marginPagesDisplayed  = {2}
									pageRangeDisplayed    = {5}
									containerClassName    = {'pagination'}
									previousClassName     = {'page-item'}
									nextClassName         = {'page-item'}
									pageClassName         = {'page-item'}
									subContainerClassName = {'pages pagination'}
									activeClassName       = {'active'}
									pageLinkClassName     = {'page-link'}
									previousLinkClassName = {'page-link'}
									nextLinkClassName     = {'page-link'}
									onPageChange          = {this.handlePagination}
									/>
									Showing { businessPagination.data_start } to { businessPagination.data_end } out of { businessPagination.data_total }
							</nav>
							:''
						}
					</div>
				</div>

			</div>
        )
    }
}

const mapStateToProps = state => ({
    auth   : state.auth,
    states : state.states,
    address: state.address
})
const mapDispatchToProps = ({
	logout,
	loadAddress,
	EditAddress,
	ReactPaginate,
})

export default withRouter(connect( mapStateToProps, mapDispatchToProps )(Address))