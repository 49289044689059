import axios from 'axios'
import * as Types from './types'


export const addBusiness = business => dispatch => {
	axios.post('business/insert', business)
		.then((res) => {
			dispatch({
				type   : Types.NEW_BUSINESS,
				payload: {
					business: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.NEW_BUSINESS,
				payload: {
					error: error.response.data
				}
			})
		})
}

export const loadBusiness = ( data ) => dispatch => {
	axios.post('business/list', data )
		.then((res) => {
			let businessData = res.data

			dispatch({
				type   : Types.LOAD_BUSINESS,
				payload: {
					business: businessData,
					error   : {}
				}
			})
		})
		.catch(error => {
			// console.log('error', typeof(error));
			// console.log('error', error);

			// dispatch({
			// 	type: Types.USERS_ERROR,
			// 	payload: {
			// 		error: error.response.data
			// 	}
			// })
		})
}

export const updateBusiness = business => dispatch => {
	axios.post('business/update', business)
		.then((res) => {
			dispatch({
				type   : Types.UPDATE_BUSINESS,
				payload: {
					business: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.UPDATE_BUSINESS,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const deleteBusiness = business => dispatch => {
	axios.post('business/delete', business)
		.then((res) => {
			dispatch({
				type   : Types.DELETE_BUSINESS,
				payload: {
					business: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.DELETE_BUSINESS,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const resetMessage = () => dispatch => {
	dispatch({
		type   : Types.UPDATE_RESET,
		payload: {
			message: null
		}
	})
}

export const addBusinessTask = business => dispatch => {
	axios.post('business/task/insert', business)
		.then((res) => {
			dispatch({
				type   : Types.BUSINESS_TASK_NEW,
				payload: {
					task: res.data.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			// console.log('Business Task', error.response);
			if(error){
				dispatch({
					type   : Types.BUSINESS_TASK_NEW,
					payload: {
						error: error.response?error.response.data:null
					}
				})
			}
		})
}

export const loadBusinessTask = ( data ) => dispatch => {
	axios.post('business/task/list', data )
		.then((res) => {
			let businessData = res.data

			dispatch({
				type   : Types.BUSINESS_TASK_LIST,
				payload: {
					business: businessData,
					error   : {}
				}
			})
		})
		.catch(error => {

		})
}

export const deleteBusinessTask = business => dispatch => {
	axios.post('business/task/delete', business)
		.then((res) => {
			dispatch({
				type   : Types.BUSINESS_TASK_DELETE,
				payload: {
					business: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.BUSINESS_TASK_DELETE,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}

export const completeBusinessTask = business => dispatch => {
	axios.post('business/task/complete', business)
		.then((res) => {
			dispatch({
				type   : Types.BUSINESS_TASK_COMPLETE,
				payload: {
					business: res.data,
					error   : {}
				}
			})
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type   : Types.BUSINESS_TASK_COMPLETE,
				payload: {
					error: (error.response)?error.response.data:null
				}
			})
		})
}