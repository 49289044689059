import axios from 'axios'
import * as Types from './types'
import setAuthToken from '../utils/setAuthToken'

export const register = (user, history) => dispatch => {
	axios.post('user/registration', user)
		.then((res) => {
			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: {}
				}
			})
			// console.log('res', res)
			// console.log('history', history)
			history.push('/login')
		})
		.catch(error => {
			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: error.response.data
				}
			})
		})
}

export const login = (user, history) => dispatch => {
	axios.post('user/login', user)
		.then((res) => {
			let token = res.data.Session
			let userData = res.data.User

			localStorage.setItem('authToken', token)
			setAuthToken( token )



			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {}
				}
			})

			history.push('/')
		})
		.catch(error => {
			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: error.response.data
				}
			})
		})
}

export const logout = (history) => dispatch => {

	localStorage.removeItem('authToken')
	history.push('/login')

	return {
		type: Types.SET_USER,
		payload: {
			user: {}
		}
	}
}

export const isLoggedin = (authToken) => dispatch => {
	axios.post('/user/islogin', authToken)
		.then((res) => {
			let userData = {
				'ami': 'Feroz'
			}

			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {}
				}
			})

			// history.push('/')
		})
		.catch(error => {
			console.log('error', typeof(error))
			console.log('error', error.response)

			if(!error.response){
				return
			}

			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: error.response.data
				}
			})
		})
}