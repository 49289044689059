import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { addBusiness, resetMessage, addBusinessTask } from  '../../store/actions/businessActions'


class NewTaskModal extends React.Component{
    state = {
		task_title   : '',
		task_category: '',
		task_source  : '',
		error        : {},
    }

	submitHandler = event =>{
		event.preventDefault()

        this.props.addBusinessTask( {
			task_title   : this.state.task_title,
			task_category: this.state.task_category,
			task_source  : this.state.task_source,
		} )
	}

	componentDidMount(){
		this.props.resetMessage()
	}

    changeHandler = event => {
		console.log('type', event.target.name )
		console.log('value', event.target.value )

        this.setState({
            [event.target.name]: event.target.value
        })
    }
	render(){

		const customStyles = {
			content : {
				top        : '50%',
				left       : '50%',
				right      : 'auto',
				bottom     : 'auto',
				marginRight: '-50%',
				width      : '580px',
				transform  : 'translate(-50%, -50%)'
			}
		};

		let { task_title,
			task_category,
			task_source } = this.state

		let { error }                = this.props.business

		let { categories, sources } = this.props
		let allCategories, allSources

		if(categories && categories.data){
			allCategories      = categories.data.length ? categories.data : null
		}
		if(sources && sources.data){
			allSources      = sources.data.length ? sources.data : null
		}

		return(
			<Modal
				isOpen         = { this.props.isOpen }
				onRequestClose = { this.props.close }
				style          = { customStyles }
			>
			<div className="row">
				<div className="col-md-12">
					<h1>Add new task</h1>

					<form onSubmit={this.submitHandler} className="">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="task_category">Category:</label>
									<select
										className = {
											( error.task_category ? "form-control is-invalid" : "form-control" )
										}
										name      = "task_category"
										onChange  = { this.changeHandler }
										>

										<option value="">Select Category</option>

										{ allCategories ? allCategories.map( itemInfo => (
											<option value={ itemInfo.CategoryID } key={ itemInfo.CategoryID }>{ itemInfo.category }</option>
										) ) : '' }

									</select>
									{ error.task_category &&(
										<div
											className="invalid-feedback">
											{ error.task_category }
										</div>
									) }
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group has-error">
									<label className="control-label" for="task_source">Source:</label>
									<select
										className = {
											( error.task_source ? "form-control is-invalid" : "form-control" )
										}
										name      = "task_source"
										onChange  = { this.changeHandler }
										>

										<option value="">Select Source</option>

										{ allSources ? allSources.map( itemInfo => (
											<option value={ itemInfo.DSID } key={ itemInfo.DSID }>{ itemInfo.ds_title }</option>
										) ) : '' }

									</select>
									{ error.task_source &&(
										<div
											className="invalid-feedback">
											{ error.task_source }
										</div>
									) }
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group has-error">
									<label className="control-label" for="task_title">Title:</label>
									<input
										type        = "text"
										className   = {
											( error.task_title ? "form-control is-invalid" : "form-control" )
										}
										placeholder = ""
										name        = "task_title"
										id          = "task_title"
										onChange    = { this.changeHandler }
										value       = { task_title }
									/>
									{ error.task_title &&(
										<div
											className="invalid-feedback">
											{ error.task_title }
										</div>
									) }
								</div>
							</div>
						</div>
						<div className="form-group has-error">
							<button
								className="btn btn-primary mr-4 mb-2 is-invalid">Submit</button>

								{ this.props.businessTask.success?
									<font className="text-success">
										{ this.props.businessTask.success }
									</font>
									:null
								}

								{ error.error &&(
									<font className="invalid-feedback">
										{ error.error }
									</font>
								) }
						</div>
					</form>

				</div>
			</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
    business    : state.business,
    businessTask: state.businessTask,
    sources     : state.source,
    categories  : state.category,
})
const mapDispatchToProps = ({
	addBusiness,
	addBusinessTask,
	resetMessage
})
export default connect( mapStateToProps, mapDispatchToProps )(NewTaskModal)
